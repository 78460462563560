// https://stackoverflow.com/a/16270434/4942191
const isInView = (divId) => {
  if (!divId) {
    return false
  }

  const footer = document.getElementById(divId)

  if (!footer) {
    return false
  }

  const rect = footer.getBoundingClientRect()

  return (
    rect.bottom > 0 &&
    rect.right > 0 &&
    rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
    rect.top < (window.innerHeight || document.documentElement.clientHeight)
  )
}

export default isInView
