import { css } from 'styled-components'

// Apply to a `styled` component to disable scrollbar
const disableScrollbar = css`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export default disableScrollbar
