// comments are for when I make a doc for whoever does tracking

const trackingIds = {
  contributorSubNav: 'contributor-sub-nav', // contributor profile
  exchangeOverview: 'exchange-overview', // exchange home
  exchangeSubnav: 'exchange-sub-nav', // podcasts home
  featureDetailBar: 'feature-detail-bar', // feature page
  feature: 'feature', // feature page
  featuresOverview: 'features-overview', // features home
  featuresSubNav: 'features-sub-nav', // features home
  featureSeries: 'feature-series', // feature series page,
  filmSeries: 'film-series', // film series page,
  filmsSubNav: 'films-sub-nav',
  filmsOverview: 'films-overview',
  homeMagazine: 'home-magazine', // home page
  homeMusic: 'home-music', // home page
  homeNews: 'home-news', // home page
  labelDetailBar: 'label-detail-bar', // label page
  labelDetail: 'label-detail', // label page
  mixOfTheDayOverview: 'mix-of-the-day-overview', // mix of the day home
  newsArticle: 'news-article', // news article
  newsArticleDetailBar: 'news-detail-bar', // news article
  newsHome: 'news-home', // news home
  newsArtistArchive: 'news-artist-archive', // news archive for artist
  newsPromoterArchive: 'news-promoter-archive', // news archive for promoter
  newsClubArchive: 'news-club-archive', // news archive for club
  playlistsOverview: 'playlists-overview', // playlists home
  playlists: 'playlists', // playlists home
  podcastOverview: 'podcast-overview', // podcasts home
  podcastSubnav: 'podcast-sub-nav', // podcasts home
  exchangeDetail: 'exchange-detail', // exchange page
  podcastDetailBar: 'podcast-detail-bar', // podcast page
  podcastDetail: 'podcast-detail', // podcast page
  reviewDetailBar: 'review-detail-bar', // review page
  reviewDetail: 'review-detail', // review page
  reviewsSubNav: 'reviews-sub-nav', // reviews home
  reviewsHome: 'reviews-home', // reviews home,
  reviewsArchive: 'reviews-archive', // reviews archive
  musicSubNav: 'music-sub-name', // music home
  musicHome: 'music-home', // music home
  techOverview: 'tech-overview', // tech home
  videosHome: 'videos-home', // videos home
  artistDetailBar: 'artist-detail-bar', // artist page
  artistDetailSubNav: 'artist-detail-sub-nav', // artist page
  artistDetailOverview: 'artist-detail-overview', // artist page
  clubDetailSubNav: 'club-detail-sub-nav', // club page
  clubDetailBar: 'club-detail-bar', // club page
  clubDetail: 'club-detail', // club page
  clubsAll: 'clubs-all', // clubs home
  eventDetailDescription: 'event-detail-description', // event page
  eventDetailTicketsModal: 'event-detail-tickets-modal', // event page
  eventDetail: 'event-detail', // event page
  eventDetailbar: 'event-detail-bar',
  eventDetailLineup: 'event-detail-lineup', // event page
  eventsSubNav: 'events-sub-nav', // events home
  eventsAll: 'events-all', // events home
  eventsOverview: 'events-overview', // events home
  eventsForYou: 'events-for-you', // events home
  eventsNew: 'events-new', // events home
  eventsRAPicks: 'events-ra-picks', // events home
  eventsNearYou: 'events-near-you', // events home
  guide: 'guide', // guide home
  homeEvents: 'home-events', // home page
  promoterDetailBar: 'promoter-detail-bar', // promoter page
  promoterDetail: 'promoter-detail', // promoter page
  promoterDetailSubNav: 'promoter-detail-sub-nav', // promoter page
  promotersAll: 'promoters-all', // promoters home
  promoterTrackingPage: 'promoter-tracking-page',
  footer: 'footer', // footer
  sitemap: 'sitemap', // sitemap
  searchModal: 'search-modal', // search modal
  myTicketsOverview: 'my-tickets-overview', // my tickets home
  myTicketsSubNav: 'my-tickets-sub-nav', // my tickets home,
  micrositeSubNav: 'microsite-sub-nav',
  proSubNav: 'pro-sub-nav',
  proUpdates: 'pro-updates',
  promoterProSubNav: 'promoter-pro-sub-nav',
  ips: 'ips',
  nav: 'nav',
  navModal: 'nav-modal',
  mobileNavModal: 'mobile-nav-modal',
  relatedArtists: 'related-artists',
  venuesMostPlayed: 'venues-most-played',
  regionsMostPlayed: 'regions-most-played',
  locationFilterModal: 'location-filter-modal',
  openLocationFilterModal: 'open-location-filter-modal',
  eventTicketBar: 'event-ticket-bar',
  logout: 'logout',
  pitchPage: 'pitch-page',
  pitchSuccessPage: 'pitch-success-page',
  profileDetailBar: 'profile-detail-bar',
  paymentDetailsPage: 'payment-details-page',
  eventRevisionPage: 'event-revision-page',
  festivalsHomeSubNav: 'festivals-home-sub-nav',
  festivalsHome: 'festivals-home',
  userFollowingPage: 'user-following-page',
  userSettingsSubNav: 'user-settings-sub-nav',
  userMarketingSettingsPage: 'user-marketing-settings-page',
  userSettingsPage: 'user-settings-page',
  privacySettingsPage: 'privacy-settings-page',
  removeProfilePage: 'remove-profile-page',
  profilePhotosPage: 'profile-photos-page',
  genreFilterModal: 'genre-filter-modal',
  eventTypeFilterModal: 'event-type-filter-modal',
  distanceFilterModal: 'distance-filter-modal',
  inbox: 'inbox',
  inboxCompose: 'inbox-compose',
  sentItems: 'sent-items',
  savedEventsSubNav: 'saved-events-sub-nav',
}

export default trackingIds
