import { Alignment, Layout, variants } from '@resident-advisor/design-system'
import { zIndex } from 'themes'
import PropTypes from 'prop-types'
import { useThemeContext } from 'hooks/useThemeContext'
import buttonTrackingIds from 'tracking/button-tracking-ids'
import CloseButton from 'components/generic/close-button'

const LoginModalControls = ({ handleClose }) => {
  const theme = useThemeContext()

  return (
    <Layout>
      <Alignment justifyContent="flex-end" zIndex={zIndex} pt={2}>
        <CloseButton
          variant={variants.button.tertiary}
          aria-label="Cancel login"
          onClick={handleClose}
          data-button-tracking-id={buttonTrackingIds.closeLoginModal}
          color={theme.login.iconColor}
        />
      </Alignment>
    </Layout>
  )
}

LoginModalControls.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

export default LoginModalControls
