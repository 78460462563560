import { NextParsedUrlQuery } from 'next/dist/server/request-meta'

const NEAR_YOU_BASE_ROUTE = '/events/location/near-you'

const formatRouteWithQueryParams = (
  filePath: string,
  contentUrl: string,
  query?: NextParsedUrlQuery
): FormattedRoute => ({
  href: { pathname: filePath, query },
  as: { pathname: contentUrl, query },
})

const formatRouteWithoutQueryParams = (
  filePath: string,
  contentUrl: string
): FormattedRoute => ({
  href: filePath,
  as: contentUrl,
})

const clubsByAreaRoute = (contentUrl: string, query?: NextParsedUrlQuery) =>
  formatRouteWithQueryParams('/clubs/[country]/[area]', contentUrl, query)

const clubByIdRoute = (contentUrl: string) =>
  formatRouteWithoutQueryParams('/clubs/[idOrCountry]', contentUrl)

const clubEventsByIdRoute = (contentUrl: string, query?: NextParsedUrlQuery) =>
  formatRouteWithQueryParams('/clubs/[idOrCountry]/events', contentUrl, query)

const clubPastEventsByIdRoute = (
  contentUrl: string,
  query?: NextParsedUrlQuery
) =>
  formatRouteWithQueryParams(
    '/clubs/[idOrCountry]/past-events',
    contentUrl,
    query
  )

const clubNewsByIdRoute = (contentUrl: string, query?: NextParsedUrlQuery) =>
  formatRouteWithQueryParams('/clubs/[idOrCountry]/news', contentUrl, query)

const djBySlugRoute = (contentUrl: string) =>
  formatRouteWithoutQueryParams('/dj/[...slug]', contentUrl)

const djTourdatesByYearRoute = (
  contentUrl: string,
  query?: NextParsedUrlQuery
) => formatRouteWithQueryParams('/dj/[...slug]', contentUrl, query)

const djPastEventsByYearRoute = (
  contentUrl: string,
  query?: NextParsedUrlQuery
) => formatRouteWithQueryParams('/dj/[...slug]', contentUrl, query)

const djNewsByIdRoute = (contentUrl: string, query?: NextParsedUrlQuery) =>
  formatRouteWithQueryParams('/dj/[...slug]', contentUrl, query)

const eventsByAreaRoute = (contentUrl: string, query?: NextParsedUrlQuery) =>
  formatRouteWithQueryParams(
    '/events/[idOrCountry]/[area]/[[...genre]]',
    contentUrl,
    query
  )

const eventsForYouByAreaRoute = (
  contentUrl: string,
  query?: NextParsedUrlQuery
) =>
  formatRouteWithQueryParams(
    '/events/[idOrCountry]/[area]/for-you',
    contentUrl,
    query
  )

const eventsRAPicksByAreaRoute = (
  contentUrl: string,
  query?: NextParsedUrlQuery
) =>
  formatRouteWithQueryParams(
    '/events/[idOrCountry]/[area]/ra-picks',
    contentUrl,
    query
  )

const eventsJustAnnouncedByAreaRoute = (
  contentUrl: string,
  query?: NextParsedUrlQuery
) =>
  formatRouteWithQueryParams(
    '/events/[idOrCountry]/[area]/just-announced',
    contentUrl,
    query
  )

const eventsByGeoLocationRoute = (
  contentUrl: string,
  query?: NextParsedUrlQuery
) =>
  formatRouteWithQueryParams(
    `${NEAR_YOU_BASE_ROUTE}/[[...genre]]`,
    contentUrl,
    query
  )

const eventsForYouByGeoLocationRoute = (
  contentUrl: string,
  query?: NextParsedUrlQuery
) =>
  formatRouteWithQueryParams(
    `${NEAR_YOU_BASE_ROUTE}/for-you`,
    contentUrl,
    query
  )

const eventsRAPicksByGeoLocationRoute = (
  contentUrl: string,
  query?: NextParsedUrlQuery
) =>
  formatRouteWithQueryParams(
    `${NEAR_YOU_BASE_ROUTE}/ra-picks`,
    contentUrl,
    query
  )

const eventsJustAnnouncedByGeoLocationRoute = (
  contentUrl: string,
  query?: NextParsedUrlQuery
) =>
  formatRouteWithQueryParams(
    `${NEAR_YOU_BASE_ROUTE}/just-announced`,
    contentUrl,
    query
  )

const exchangeByIdRoute = (contentUrl: string) =>
  formatRouteWithoutQueryParams('/exchange/[id]', contentUrl)

const featureSeriesRoute = (contentUrl: string) =>
  formatRouteWithoutQueryParams('/features/series/[series]', contentUrl)

const featureRoute = (contentUrl: string) =>
  formatRouteWithoutQueryParams('/features/[id]', contentUrl)

const festivalsByCountryRoute = (query?: NextParsedUrlQuery) => {
  const { country, ...rest } = query
  return formatRouteWithQueryParams(
    '/festivals/[country]',
    `/festivals/${country}`,
    rest
  )
}

const filmSeriesRoute = (contentUrl: string) =>
  formatRouteWithoutQueryParams('/films/series/[series]', contentUrl)

const filmRoute = (contentUrl: string) =>
  formatRouteWithoutQueryParams('/films/[id]', contentUrl)

const guideByAreaRoute = (contentUrl: string, query?: NextParsedUrlQuery) =>
  formatRouteWithQueryParams('/guide/[country]/[area]', contentUrl, query)

const labelByIdRoute = (contentUrl: string) =>
  formatRouteWithoutQueryParams('/labels/[id]', contentUrl)

const newsByIdRoute = (contentUrl: string) =>
  formatRouteWithoutQueryParams('/news/[id]', contentUrl)

const newsWithCategoryRoute = (type: string) =>
  `/news?category=${type?.toLowerCase()}`

const profileByUsernameRoute = (
  contentUrl: string,
  query?: NextParsedUrlQuery
) => formatRouteWithQueryParams('/profile/[username]', contentUrl, query)

const promotersByAreaRoute = (contentUrl: string, query?: NextParsedUrlQuery) =>
  formatRouteWithQueryParams('/promoters/[country]/[area]', contentUrl, query)

const promoterByIdRoute = (contentUrl: string) => {
  const filePath = contentUrl.startsWith('/clubs')
    ? '/clubs/[idOrCountry]'
    : '/promoters/[idOrCountry]'
  return formatRouteWithoutQueryParams(filePath, contentUrl)
}

const promoterEventsByIdRoute = (
  contentUrl: string,
  query?: NextParsedUrlQuery
) =>
  formatRouteWithQueryParams(
    '/promoters/[idOrCountry]/events',
    contentUrl,
    query
  )

const promoterPastEventsByIdRoute = (
  contentUrl: string,
  query?: NextParsedUrlQuery
) =>
  formatRouteWithQueryParams(
    '/promoters/[idOrCountry]/past-events',
    contentUrl,
    query
  )

const promoterNewsByIdRoute = (
  contentUrl: string,
  query?: NextParsedUrlQuery
) =>
  formatRouteWithQueryParams('/promoters/[idOrCountry]/news', contentUrl, query)

const pitchByTypeRoute = (type: string, query?: NextParsedUrlQuery) =>
  formatRouteWithQueryParams('/pitch/[type]', `/pitch/${type}`, query)

const podcastByIdRoute = (contentUrl: string) =>
  formatRouteWithoutQueryParams('/podcast/[id]', contentUrl)

const reviewByIdRoute = (contentUrl: string) =>
  formatRouteWithoutQueryParams('/reviews/[reviewId]', contentUrl)

const reviewsByTypeRoute = (url: string, query?: NextParsedUrlQuery) =>
  formatRouteWithQueryParams(url, url, query)

const reviewsByTypeAndGenreRoute = (type: string, genre: string) =>
  formatRouteWithQueryParams(
    `/reviews/${type.toLowerCase()}/[genre]`,
    `/reviews/${type.toLowerCase()}`,
    { genre }
  )

const eventRoute = (contentUrl: string, useSSR = false) => {
  if (useSSR) {
    return {
      href: contentUrl,
      useSSR: true,
    }
  }

  return {
    href: '/events/[idOrCountry]',
    as: contentUrl,
  }
}

const revisionByIdRoute = (
  eventId: number,
  revisionId: number,
  query?: NextParsedUrlQuery
) =>
  formatRouteWithQueryParams(
    '/pro/event/[id]/revision/[revisionId]',
    `/pro/event/${eventId}/revision/${revisionId}`,
    query
  )

type FormattedRoute = {
  href: string | { pathname: string; query?: NextParsedUrlQuery }
  as: string | { pathname: string; query?: NextParsedUrlQuery }
}

type DynamicRouteFormatter = (
  contentUrl: string,
  query?: NextParsedUrlQuery
) => FormattedRoute

export type { DynamicRouteFormatter, FormattedRoute }
export {
  clubsByAreaRoute,
  clubByIdRoute,
  clubEventsByIdRoute,
  clubPastEventsByIdRoute,
  clubNewsByIdRoute,
  djBySlugRoute,
  djTourdatesByYearRoute,
  djPastEventsByYearRoute,
  djNewsByIdRoute,
  eventRoute,
  eventsByAreaRoute,
  eventsForYouByAreaRoute,
  eventsRAPicksByAreaRoute,
  eventsJustAnnouncedByAreaRoute,
  eventsByGeoLocationRoute,
  eventsForYouByGeoLocationRoute,
  eventsRAPicksByGeoLocationRoute,
  eventsJustAnnouncedByGeoLocationRoute,
  exchangeByIdRoute,
  featureSeriesRoute,
  featureRoute,
  festivalsByCountryRoute,
  filmSeriesRoute,
  filmRoute,
  formatRouteWithQueryParams,
  guideByAreaRoute,
  labelByIdRoute,
  newsByIdRoute,
  newsWithCategoryRoute,
  pitchByTypeRoute,
  profileByUsernameRoute,
  promotersByAreaRoute,
  promoterByIdRoute,
  promoterEventsByIdRoute,
  promoterPastEventsByIdRoute,
  promoterNewsByIdRoute,
  podcastByIdRoute,
  reviewByIdRoute,
  reviewsByTypeRoute,
  reviewsByTypeAndGenreRoute,
  revisionByIdRoute,
}
