import { useApolloClient } from '@apollo/client'
import axios from 'axios'
import { setCookie } from 'lib/cookieHelpers'
import Router from 'next/router'
import getConfig from 'next/config'
import GET_USER_QUERY from 'context/UserContext/queries/GetUserQuery'
import cookieNames from 'lib/cookieNames'
import { logger } from 'lib/logger'
import errors from 'messages/errors'
import Tracking, { TRACKING_EVENT, TRACKING_PROPERTY } from 'scripts/tracking'
import AUTH_API_VERSION from 'enums/auth-api-version'

const { publicRuntimeConfig } = getConfig()

const LOGIN_ROUTE_V2 = `${publicRuntimeConfig.AUTH_SERVICE}/api/${AUTH_API_VERSION.v2}/login`

const useRALogin = ({
  returnUrl,
  reloadUrl = undefined,
  onSuccess,
  source,
}) => {
  const apolloClient = useApolloClient()

  return async (values, actions) => {
    try {
      const method = getLoginMethod(values)
      Tracking.trackMixpanel(TRACKING_EVENT.loginAttempt, {
        'Login Method': method,
        'Login Source': source,
      })

      // Based on the feature switch we choose what login call to make
      const response = await login(values)

      await processLoginResponse({
        response,
        apolloClient,
        returnUrl,
        reloadUrl,
        onSuccess,
        onError: (e) => handleLoginError(e, actions),
        source,
        method,
      })
    } catch (e) {
      handleLoginError(e, actions)
    } finally {
      actions.setSubmitting(false)
    }
  }
}

const login = async (values) =>
  axios.post(LOGIN_ROUTE_V2, values, { withCredentials: true })

const handleLoginError = (e, actions) => {
  const statusCode = e.response?.status || 500
  const loginErrorCode = e.response?.data?.errorCode

  actions.setStatus({ statusCode: loginErrorCode || statusCode })
}

const statusCodeMessages = (intl, isPasswordOnlyForm) => ({
  IncorrectUsernameOrPassword: isPasswordOnlyForm
    ? intl.formatMessage(errors.incorrectPassword)
    : intl.formatMessage(errors.incorrectUsernameOrPassword),
  EmailNotVerified: intl.formatMessage(errors.emailNotVerified),
  GenericError: intl.formatMessage(errors.serverError),
  TooManyAttemptsTryLater: intl.formatMessage(errors.tooManyAttemptsTryLater),
  500: intl.formatMessage(errors.serverError),
})

const processLoginResponse = async ({
  response,
  apolloClient,
  onSuccess,
  onError,
  returnUrl,
  reloadUrl,
  source,
  method,
}) => {
  try {
    // The refresh token is HttpOnly so we do not know in the JS code whether it is present or not
    // The getTokenHelpers code checks a flag set by the server to know - so we need to override this
    // when client side logs in
    setCookie(cookieNames.successfulLoginDuringSession, true)

    Tracking.trackMixpanel(TRACKING_EVENT.loginSuccess, {
      'Login Method': method,
      'Login Source': source,
    })

    // Call this here so any changes are applied
    // on the API before resetting the cache
    if (onSuccess) {
      await onSuccess(response)
    }

    if (apolloClient) {
      // Force a reload of all the current queries now that the user is
      // logged in
      await apolloClient.cache.reset()
      await apolloClient.query({ query: GET_USER_QUERY })
    }

    // Forces a reload of the page. The main reason to do this is to force feature switches to update,
    // as after login the feature switch values are not deterministic and may still have logged-out values
    // https://app.shortcut.com/resident-advisor/story/35775/unleash-client-proxy-is-initialised-twice
    if (reloadUrl) {
      // eslint-disable-next-line xss/no-location-href-assign
      window.location.href = `${window.origin}${reloadUrl}`
    } else if (returnUrl) {
      // TODO: it should redirect to the page the user is coming from
      Router.replace(returnUrl)
    }
  } catch (e) {
    logger.error(`Error processing login response: ${e}`)

    if (onError) {
      onError(e)
    }
  }
}

const getLoginMethod = (values) => {
  if (!values?.usernameOrEmail || typeof values.usernameOrEmail !== 'string') {
    return TRACKING_PROPERTY.loginMethod.unknown
  }

  return values.usernameOrEmail.includes('@')
    ? TRACKING_PROPERTY.loginMethod.email
    : TRACKING_PROPERTY.loginMethod.username
}

export default useRALogin
export {
  handleLoginError,
  useRALogin,
  processLoginResponse,
  statusCodeMessages,
}
