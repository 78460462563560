import { Text, Alignment, variants } from '@resident-advisor/design-system'
import LanguageFilter from 'components/shared/language-filter'
import { useIntl } from 'react-intl'
import messages from 'messages/dict'

const LanguageSelector = () => {
  const intl = useIntl()

  return (
    <Alignment>
      <Text variant={variants.text.small} color="secondary" mr={2}>
        {intl.formatMessage(messages.displayLanguage)}
      </Text>
      <LanguageFilter />
    </Alignment>
  )
}

export default LanguageSelector
