import styled from 'styled-components'
import {
  Alignment,
  Box,
  CloseIcon,
  variants,
  gridSizes,
} from '@resident-advisor/design-system'
import usePageHeight from 'hooks/usePageHeight'
import { FullWidthAbsolutePosition } from 'components/generic/full-width'
import Sticky from 'components/generic/sticky'
import Modal from 'components/generic/modal'
import { useAnimationContext } from 'context/AnimationContext'
import { EaseInFromRightSide } from 'components/shared/animation'
import buttonTrackingIds from 'tracking/button-tracking-ids'
import disableScrollbar from 'lib/disableScrollbar'
import CloseButton from 'components/generic/close-button'
import { StickyContextProvider } from 'context/StickyContext'
import { zIndex as themeZIndex } from 'themes'
import { AriaRole, PropsWithChildren } from 'react'
import { SpaceProps } from 'styled-system'
import { useThemeContext } from 'hooks/useThemeContext'

const SideModal = ({
  toggle,
  children,
  backgroundOpacity,
  backgroundColor,
  zIndex = themeZIndex.modal,
  topOffset,
  ...props
}: PropsWithChildren<SideModalProps>) => {
  const theme = useThemeContext()
  return (
    <Modal
      toggle={toggle}
      alignItems="flex-end"
      topOffset={topOffset}
      focus
      backgroundOpacity={backgroundOpacity ?? theme.sideModal.opacity}
      zIndex={zIndex}
    >
      <SideModalContent
        toggle={toggle}
        backgroundColor={backgroundColor ?? theme.sideModal.backgroundColor}
        {...props}
      >
        {children}
      </SideModalContent>
    </Modal>
  )
}

type SideModalProps = {
  closeIcon?: React.FC
  toggle: () => void
  backgroundOpacity?: number
  backgroundColor?: string
  mSpan?: number
  lSpan?: number
  px?: SpaceProps['px']
  zIndex?: number
  renderHeader?: () => JSX.Element
  role?: AriaRole
  topOffset?: string
}

const SideModalContent = ({
  toggle,
  children,
  closeIcon = CloseIcon,
  backgroundColor,
  mSpan = 4,
  lSpan = 4,
  px = 3,
  renderHeader,
  role = 'menu',
}: PropsWithChildren<Omit<SideModalProps, 'backgroundOpacity' | 'zIndex'>>) => {
  const pageHeight = usePageHeight()
  const [, setIsAnimating] = useAnimationContext()

  return (
    <FullWidthAbsolutePosition
      sizes={['s', 'm', 'l']}
      style={{ pointerEvents: 'none' }}
    >
      <EaseInFromRightSide
        onAnimationStart={() => setIsAnimating(true)}
        onAnimationComplete={() => setIsAnimating(false)}
        transitions={undefined}
      >
        <Box
          mr={0}
          ml="auto"
          width={{
            s: '100%',
            m: (gridSizes.m.content / 8) * mSpan,
            l: (gridSizes.l.content / 12) * lSpan,
            xl: (gridSizes.xl.content / 12) * lSpan,
          }}
        >
          <Alignment
            px={px}
            flexDirection="column"
            backgroundColor={backgroundColor}
            style={{
              pointerEvents: 'auto',
            }}
            minHeight={pageHeight}
            role={role}
          >
            <ScrollArea height={pageHeight}>
              {renderHeader ? (
                renderHeader()
              ) : (
                <SideModalHeader toggle={toggle} closeIcon={closeIcon} />
              )}
              <Alignment flexGrow="1" flexDirection="column">
                {children}
              </Alignment>
            </ScrollArea>
          </Alignment>
        </Box>
      </EaseInFromRightSide>
    </FullWidthAbsolutePosition>
  )
}

const ScrollArea = styled(Box)`
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  ${disableScrollbar};
`

const SideModalHeader = ({
  toggle,
  closeIcon,
}: Pick<SideModalProps, 'toggle' | 'closeIcon'>) => (
  <StickyContextProvider offset={0}>
    <Sticky>
      <Alignment justifyContent="flex-end" py={3}>
        <CloseButton
          variant={variants.button.tertiary}
          onClick={toggle}
          description="Close"
          data-button-tracking-id={buttonTrackingIds.closeFilterModal}
          closeIcon={closeIcon}
          iconSize={undefined}
        />
      </Alignment>
    </Sticky>
  </StickyContextProvider>
)

export type { SideModalProps }
export { SideModalHeader, SideModalContent, ScrollArea, SideModal as default }
