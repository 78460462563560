import PropTypes, { InferProps } from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { useThemeContext } from 'hooks/useThemeContext'
import Modal from 'components/generic/modal'
import { dark, opacity } from 'themes'
import { useRouter } from 'next/router'
import { useRALogin } from 'components/auth/useRALoginWrapper'
import { Alignment } from '@resident-advisor/design-system'
import { FadeIn } from 'components/shared/animation'
import dynamic from 'next/dynamic'
import testIds from 'enums/testIds'
import LoginModalControls from './LoginModalControls'

const DynamicLoginModalContent = dynamic(() => import('./LoginModalContent'))

const LoginModal: React.FC<LoginModalProps> = ({
  toggleShow,
  onSuccess,
  source,
  zIndex,
  returnUrl,
}) => {
  const { asPath } = useRouter()
  const theme = useThemeContext()

  const redirectUrl = returnUrl ?? asPath
  const handleLogin = useRALogin({
    returnUrl: redirectUrl,
    onSuccess,
    source,
  })

  return (
    <ThemeProvider theme={dark}>
      <Modal
        toggle={toggleShow}
        backgroundOpacity={opacity.loginModalBackground}
        zIndex={zIndex}
        data-testid={testIds.loginModal}
      >
        <FadeIn>
          <Alignment
            width="100%"
            height={{ m: '100%' }}
            flexDirection="column"
            position="absolute"
            bg={theme.login.backgroundColor}
          >
            <LoginModalControls handleClose={toggleShow} />
            <DynamicLoginModalContent
              onSubmit={handleLogin}
              source={source}
              oauthRedirectUri={redirectUrl}
            />
          </Alignment>
        </FadeIn>
      </Modal>
    </ThemeProvider>
  )
}

const loginModalPropTypes = {
  toggleShow: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  source: PropTypes.string,
  zIndex: PropTypes.number,
  returnUrl: PropTypes.string,
}

LoginModal.propTypes = loginModalPropTypes

type LoginModalProps = InferProps<typeof loginModalPropTypes>

export default LoginModal
