import { useState } from 'react'
import { variants } from '@resident-advisor/design-system'
import { AnimatePresence } from 'framer-motion'
import ButtonLink from 'components/generic/button-link'
import { useI18nContext } from 'context/I18nContext'
import SideModal from 'components/generic/side-modal'
import buttonTrackingIds from 'tracking/button-tracking-ids'
import dynamicLoadWithSpinner from 'lib/dynamicLoadWithSpinner'
import { useQuery } from '@apollo/client'
import Skeleton from 'react-loading-skeleton'
import LocaleDto from 'interfaces/gql/LocaleDto'
import GET_LOCALES_QUERY from 'queries/GetLocalesQuery'

import { LanguageFilterContentProps } from './LanguageFilterContent'

const LanguageFilterContent =
  dynamicLoadWithSpinner<LanguageFilterContentProps>(
    () => import('./LanguageFilterContent')
  )

const LanguageFilter = () => {
  const { state: i18nState } = useI18nContext()

  const currentLocale = i18nState.locale

  const [show, setShow] = useState(false)
  const toggleShow = () => setShow(!show)

  const { data, loading } = useQuery<{ locales: [LocaleDto] }>(
    GET_LOCALES_QUERY
  )

  const selectedLocale = data?.locales?.find(
    (x) => x.ref.toLocaleLowerCase() === currentLocale?.toLocaleLowerCase()
  )

  // Use untranslated "English" if selected locale not in the
  // list returned from the API - this shouldn't occur, so is just a fallback
  const selectedLocaleTitle = selectedLocale?.localTitle
    ? selectedLocale.localTitle
    : 'English'

  return (
    <>
      <ButtonLink
        onClick={toggleShow}
        variant={variants.text.small}
        data-button-tracking-id={buttonTrackingIds.openLanguageFilterModal}
        dataTestId="language-filter-toggle"
      >
        {loading ? <Skeleton width={100} height={20} /> : selectedLocaleTitle}
      </ButtonLink>
      <AnimatePresence>
        {show && (
          <SideModal toggle={toggleShow}>
            <LanguageFilterContent locales={data?.locales} />
          </SideModal>
        )}
      </AnimatePresence>
    </>
  )
}

export default LanguageFilter
