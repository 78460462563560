import { gql } from '@apollo/client'

const GET_LOCALES_QUERY = gql`
  query GET_LOCALES_QUERY {
    locales {
      id
      title
      localTitle
      ref
    }
  }
`

export default GET_LOCALES_QUERY
