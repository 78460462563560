import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Text, Alignment, variants } from '@resident-advisor/design-system'
import ButtonLink from 'components/generic/button-link'
import { useI18nContext, i18nActions } from 'context/I18nContext'
import cookieNames from 'lib/cookieNames'
import { setCookie } from 'lib/cookieHelpers'
import messages from 'messages/dict'
import buttonTrackingIds from 'tracking/button-tracking-ids'
import { useRouter } from 'next/router'
import LocaleDto from 'interfaces/gql/LocaleDto'

type LanguageFilterContentProps = {
  locales: LocaleDto[]
  returnUrl?: string
}

const LanguageFilterContent = ({
  locales,
  returnUrl,
}: LanguageFilterContentProps) => {
  const intl = useIntl()
  const { state: i18nState, dispatch } = useI18nContext()

  const currentLocale = i18nState.locale?.toLocaleLowerCase()

  const router = useRouter()
  const { pathname, asPath, query } = router

  const setSelectedLocale = (selectedLocale) => {
    if (selectedLocale !== currentLocale) {
      setCookie(cookieNames.locale, selectedLocale)
      dispatch({ type: i18nActions.SET_LOCALE, value: selectedLocale })

      if (returnUrl) {
        router.push(returnUrl, undefined, {
          locale: selectedLocale,
        })
      } else {
        router.push({ pathname, query }, asPath, {
          locale: selectedLocale,
        })
      }
    }
  }

  return (
    <>
      <Text variant={variants.text.label} color="tertiary">
        {intl.formatMessage(messages.displayLanguage)}
      </Text>
      <Alignment flexDirection="column" my={3}>
        {locales.map((locale) => {
          const selectedLocale =
            locale.ref?.toLocaleLowerCase() === currentLocale

          return (
            <ButtonLink
              key={locale.id}
              mb={2}
              onClick={() => setSelectedLocale(locale.ref.toLocaleLowerCase())}
              variant={variants.text.heading.m}
              color={selectedLocale ? 'accent' : 'primary'}
              dataTestId={selectedLocale ? 'selected' : 'notselected'}
              data-button-tracking-id={`${buttonTrackingIds.setLocale}-${locale.ref}`}
            >
              {locale.localTitle}
            </ButtonLink>
          )
        })}
      </Alignment>
    </>
  )
}

LanguageFilterContent.propTypes = {
  returnUrl: PropTypes.string,
}

export type { LanguageFilterContentProps }
export default LanguageFilterContent
