import css from '@styled-system/css'
import { Text } from '@resident-advisor/design-system'
import PropTypes from 'prop-types'
import { ButtonHTMLAttributes, PropsWithChildren } from 'react'
import { SpaceProps } from 'styled-system'
import { TestProps } from 'lib/testUtils'

const buttonStyles = (hoverColor) => ({
  cursor: 'pointer',
  '&:hover': {
    color: hoverColor ?? 'accent',
  },
})

interface ButtonLinkProps
  extends SpaceProps,
    ButtonHTMLAttributes<HTMLButtonElement>,
    TestProps {
  hoverColor?: string
  variant?: string
  target?: string
  fontWeight?: string
}

const ButtonLink = ({
  hoverColor,
  ...props
}: PropsWithChildren<ButtonLinkProps>) => (
  <Text role="button" css={css(buttonStyles(hoverColor))} {...props} />
)

ButtonLink.propTypes = {
  hoverColor: PropTypes.string,
  variant: PropTypes.string,
}

export default ButtonLink
