import { useState, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import {
  Button,
  Text,
  Box,
  ArrowLeftIcon,
  CloseIcon,
  variants,
} from '@resident-advisor/design-system'
import { ThemeProvider } from 'styled-components'
import { dark } from 'themes'
import FilterModalSearchInput from 'components/generic/filter-modal-search-input'
import SideModal from 'components/generic/side-modal'
import messages from 'messages/dict'
import eventMessages from 'messages/events'
import buttonTrackingIds from 'tracking/button-tracking-ids'
import trackingIds from 'tracking/tracking-ids'
import dynamicLoadWithSpinner from 'lib/dynamicLoadWithSpinner'

const DynamicLocationModalContent = dynamicLoadWithSpinner(() =>
  import('./LocationModalContent')
)

const DynamicAllLocationsFilter = dynamicLoadWithSpinner(
  () => import('./all-locations-filter'),
  { fullScreenLoading: true }
)

const SCREENS = {
  LOCATION_SEARCH: 'location-search',
  ALL_LOCATIONS: 'all-locations',
}

const initialState = [SCREENS.LOCATION_SEARCH]

const ACTIONS = {
  ADD_TO_STACK: 'ADD_TO_STACK',
  POP_FROM_STACK: 'POP_FROM_STACK',
  CLEAR_STACK: 'CLEAR_STACK',
}

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ADD_TO_STACK:
      return [...state, action.payload]
    case ACTIONS.POP_FROM_STACK:
      return [...state.slice(0, state.length - 1)]
    case ACTIONS.CLEAR_STACK:
      return []
    default:
      return state
  }
}

const LocationModal = ({
  area,
  onLocationSelect,
  toggle,
  showPreciseLocationFilter = true,
}) => {
  const intl = useIntl()
  const [filterText, setFilterText] = useState('')
  const [state, dispatch] = useReducer(reducer, initialState)

  const handleToggleContent = () => {
    if (state.length > 1) {
      dispatch({ type: ACTIONS.POP_FROM_STACK })
    } else {
      setFilterText('')
      dispatch({ type: ACTIONS.CLEAR_STACK })
      toggle()
    }
  }

  const showLocationSearch = state[state.length - 1] === SCREENS.LOCATION_SEARCH
  const showAllLocations = state[state.length - 1] === SCREENS.ALL_LOCATIONS

  return (
    <ThemeProvider theme={dark}>
      <SideModal
        toggle={handleToggleContent}
        closeIcon={showAllLocations ? ArrowLeftIcon : CloseIcon}
        data-tracking-id={trackingIds.locationFilterModal}
      >
        {showLocationSearch && (
          <>
            <Text variant={variants.text.label} color="tertiary">
              {intl.formatMessage(messages.location)}
            </Text>
            <FilterModalSearchInput
              placeholder={intl.formatMessage(messages.typeHere)}
              onChange={setFilterText}
            />
            <DynamicLocationModalContent
              filterText={filterText}
              onLocationSelect={onLocationSelect}
              showPreciseLocationFilter={showPreciseLocationFilter}
            />
            <Box>
              <Button
                mb={2}
                variant={variants.button.secondaryGrey}
                onClick={() =>
                  dispatch({
                    type: ACTIONS.ADD_TO_STACK,
                    payload: SCREENS.ALL_LOCATIONS,
                  })
                }
                data-button-tracking-id={buttonTrackingIds.viewAllLocations}
              >
                {intl.formatMessage(eventMessages.viewAllLocations)}
              </Button>
            </Box>
          </>
        )}

        {showAllLocations && (
          <DynamicAllLocationsFilter
            area={area}
            onLocationSelect={onLocationSelect}
            toggleShow={() => dispatch({ type: ACTIONS.POP_FROM_STACK })}
          />
        )}
      </SideModal>
    </ThemeProvider>
  )
}

LocationModal.propTypes = {
  area: PropTypes.shape({
    country: PropTypes.shape({
      urlCode: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
    urlName: PropTypes.string.isRequired,
  }).isRequired,
  onLocationSelect: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  showPreciseLocationFilter: PropTypes.bool,
}

export { reducer, ACTIONS, SCREENS }
export default LocationModal
